import throttle from 'lodash/throttle'
import { useEffect } from 'react'
import { useB2BContext } from '@contexts/b2b'
import { useScreenContext } from '@contexts/screen'

export default function useResponsiveViewHeight() {
  const { isLarge, isSmall } = useScreenContext()
  const { isB2B } = useB2BContext()

  const isSmallB2B = isB2B && isSmall

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (isLarge) return
    //set --responsive-vh on mount
    setResponsiveVh()

    //update --responsive-vh on resize
    window.addEventListener('resize', throttle(setResponsiveVh))
  }, [])

  useEffect(() => {
    if (!isSmallB2B) return
    setResponsiveVh()

    window.addEventListener('orientationchange', setResponsiveVh)
    return () => window.removeEventListener('orientationchange', setResponsiveVh)
  }, [])
}

function setResponsiveVh() {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--responsive-vh', `${vh}px`)
}
