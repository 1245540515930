import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { dayjs } from '@utils/dayjs'
import { useModal } from './useModal'

// 셀링 페이지의 상품 BM이 업데이트 되어야 하는 시간
const SELLING_UPDATE_TIME = '2024-09-19T14:59:59'
export const isBM5_1_1 = dayjs().isAfter(SELLING_UPDATE_TIME)

/**
 * @description 모달 버튼 클릭 시 새로고침이 아닌 다른 페이지로 리다이렉트 되어야 하는 경우 페이지 정보 정의
 * @example [{ source: '/promotion/package/ipad', / destination: sellingPaths.promotionAllInOnePass() }]
 */
type RedirectInfo = { source: string; destination: URL }
const REDIRECT_INFO: RedirectInfo[] = []

/**
 * 셀링 페이지의 상품 BM이 특정 시간에 업데이트 되어야 하는 경우 이전시간에 접속한 유저들에게 새로고침을 유도하는 모달을 띄워줍니다.
 */
const useSellingUpdateModal = () => {
  const router = useRouter()
  const { openModal } = useModal()
  const now = dayjs()
  const diff = dayjs(SELLING_UPDATE_TIME).diff(now)
  const isOver = now.isAfter(SELLING_UPDATE_TIME)

  const isPromotionPage = router.pathname.includes('/promotion')

  const handleClick = () => {
    const redirectInfo = REDIRECT_INFO.find((info) => router.asPath.includes(info.source))
    if (redirectInfo) {
      void router.replace(redirectInfo.destination)
    } else {
      router.reload()
    }
  }

  const refreshNotice = () =>
    openModal({
      type: 'common/Alert',
      props: {
        title: '신규 혜택 오픈!',
        message: '지금 사이트를 새로고침하고 새로운 혜택을 만나보세요',
        onClose: handleClick,
        confirmText: '확인',
      },
      overlayOptions: {
        closeOnOverlayClick: false,
        className: 'high-priority',
      },
    })

  useEffect(() => {
    if (isPromotionPage && !isOver) {
      const timerId = setTimeout(refreshNotice, diff)
      return () => clearTimeout(timerId)
    }
  }, [isPromotionPage, diff, isOver])
}

export default useSellingUpdateModal
