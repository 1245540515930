import { useAtomValue } from 'jotai'
import { siteEntranceTimeAtom } from '@store/modules/promotion/atoms'
import { useNProgress } from '@hoc/withPageEvent'
import { usePreventScrollJump } from '@hoc/withPageEvent'
import useDrawAsciiArt from '@hooks/useDrawAsciiArt'
import useResponsiveViewHeight from './useResponsiveViewHeight'
import useSellingUpdateModal from './useSellingUpdateModal'

export default function UseWebHook() {
  useDrawAsciiArt()
  useNProgress()
  usePreventScrollJump()
  useAtomValue(siteEntranceTimeAtom)
  useResponsiveViewHeight()
  useSellingUpdateModal()

  return null
}
