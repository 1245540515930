import { useEffect } from 'react'
import packageJson from '../../package.version.json'

export default function useDrawAsciiArt() {
  useEffect(() => {
    console.info(
      String.raw`%c
   ____  ____   __   __     ___  __     __   ____  ____  ____ 
  (  _ \(  __) / _\ (  )   / __)(  )   / _\ / ___)/ ___)(___ \ 
   )   / ) _) /    \/ (_/\( (__ / (_/\/    \\___ \\___ \ / __/ 
  (__\_)(____)\_/\_/\____/ \___)\____/\_/\_/(____/(____/(____) 
  
  v${packageJson.version}
  `,
      `color:#00d37a`
    )
    console.info(
      `%cWe need your` +
        ` %ctalents 🤸` +
        ` %cLet's` +
        ` %ctravel` +
        ` %cwith us 🍻 \nhttps://www.qualson.com/recruit`,
      `color:#27ae60`,
      `color:#e74c3c`,
      `color:#ffdc4e`,
      `color:#816cff`,
      `color:#2980b9`
    )
  }, [])
}
