import { useRouter } from 'next/router'
import Nprogress from 'nprogress'
import { useEffect } from 'react'

export function useNProgress() {
  const router = useRouter()

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', () => {
      if (!document.body) return
      Nprogress.start()
    })
    window.addEventListener('load', () => {
      Nprogress.done()
    })
    router.events.on('routeChangeStart', () => {
      Nprogress.start()
    })
    router.events.on('routeChangeComplete', () => {
      Nprogress.done()
    })
    router.events.on('routeChangeError', () => {
      Nprogress.done()
    })
  }, [])
}

export function usePreventScrollJump() {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      document.documentElement.style.scrollBehavior = 'unset'
    })
    router.events.on('routeChangeComplete', () => {
      window.requestAnimationFrame(() => {
        document.documentElement.style.scrollBehavior = 'smooth'
      })
    })
  }, [])
}
